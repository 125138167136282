.AboutPageCreation {
  margin-top: 3vw;
  .submit_reset {
    // background-color: red;
    display: flex;
    justify-content: end;
    gap: 20px;
  }
  .viewAndManage {
    margin: 4px 0 32px 0;
  }
  .mainTitle {
    background-color: #f5fbfe;
    padding: 24px;
    margin-bottom: 48px;

    .inputFieldsSize {
      display: flex;
      gap: 30px;
      .textField {
        width: 32%;
      }
      .autherDesc {
        width: 70%;
      }
    }
  }
}
