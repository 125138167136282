.dragAndDrop {
  border: 2px dashed #16056b;
  border-radius: 10px;
  margin: 12px 0px !important;
  display: flex;
  justify-content: center;
  height: 8rem;
  align-items: center;
  cursor: pointer;
  width: 100%;
  .innerDrag {
    display: flex;
  }
}
.imageByUser {
  width: 100%;
  position: relative;
  .close {
    height: 24px;
    width: 24px;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 100;
    background-color: rgb(237, 170, 170);
    cursor: pointer;
  }
}
