.blogSix {
.blogWrapper{
  margin: 95px 0px 80px 0px;
  display: flex;
  width: 100%;
  .imageBox{
    width: 50%;
  }
  .textBox{
    width: 50%;
    padding: 0px 32px ;
  }
  .titleWrapper{
    display: flex;
    justify-content: center;
   }
  
   .title{
    margin: 80px 0px 32px 0px;
    width: 100%;
    text-align: center;
   }
   .paraOne::first-letter {
    font-size: 48px;
   }
   .paraOne{
    margin-bottom: 40px;
   }
   .paraTwo{
    margin-top: 40px;
   }
  
}
.bloglistMarginBottom {
  margin: 0vw 5.6vw 8.333vw 5.6vw;
}
.blogAd{
  margin: 0vw 5.6vw 8.333vw 5.6vw;
}


}


