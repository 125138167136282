.generalTab{
    margin-top: 6vw;
    display:flex;
    justify-content: center;
    align-items: center;
    width:100%;
    .innerGeneral{
       
        .firstLine{
            display:flex;
            justify-content: space-between;
            .input {
                width: 461px;
                margin:1em;
                border-radius: 4px;
              }
        }
        .btnBlock{
            display:flex;
            justify-content: space-between;
            .btn {
                margin:1em;
                width: 461px;
              }
        }
        
    }
}