.couponBlock{
    .hurryTextBlock{
        margin: 24px 0 16px 0;
    }
    .clickMeBtn{
        display:flex;
        justify-content: center;
    }
    .picHeading{
        display: flex;
        justify-content: space-between;
        margin: 24px 0 8px 0;
    }
    .iconBlock{
        display: flex;
        justify-content: space-between;
    }
    .imgBlock{
        position: relative;
    }
    .theCoupon{
        position: absolute;
        top: 6.20em;
        left:5em;
    }
}