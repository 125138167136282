.privacyPolicyBlock {
  .centerTextBlock {
    display: flex;
    justify-content: center;
    position: relative;
    .bottomText {
      background-color: #fff;
      padding: 6vw 5vw 1vw 5vw;
      width: 86.111vw;
      box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.2);
      margin-bottom: 6.944vw;
      margin-top: -13.889vw;
      text-align: justify;
    }
  }
}
