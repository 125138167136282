.faqs {

 .bottomWrapper{
  margin: 0px 5.6vw 8.333vw 5.6vw;
 .title{
  padding-bottom: 32px;
  margin-top: 80px;
  width: 100%;
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 48px;
 }
 .para{
  margin-bottom: 32px;
 }
 }
 .contactUsBlock{
   margin-bottom: 5.56vw;
 }
}

