.forgotblock {
    margin: 0px;
    padding: 0px;
    display: flex;
    background-color: #161616;
    .leftPicBlock {
      width: 50%;
    }
    .signup {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .createText{
          text-align: left;
      }
      .letStarted {
        margin: 1.11vw 0vw 2.11vw 0vw;
      }
     
      .inputWidth {
        margin: 1.111vw 0vw 1.111vw 0vw;
        background-color: #454545;
      }
   
      .createBtn {
        width: 33vw;
      height:60px;

        margin-top: 1vw;
        margin-bottom: 1.667vw;
      }
    }
  }
 
  @media only screen and (min-width: 300px) and (max-width: 768px) {
    .forgotblock {
      margin: 0px;
      padding: 0px;
      display: flex;
      background-color: #161616;
      .leftPicBlock {
        display: none;
      }
      .signup {
        width: 100%;
        height: 100vh;
        margin-left: 50px;
        .inputWidth {
          width:80%;
          }
          .createBtn {
            width: 80%;
            height: 60px;
            margin-top: 2vw;
            margin-bottom: 2.667vw;
          }
      }
    }
  }