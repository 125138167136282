.userDetailTab {
  margin-top: 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .imgBlock {
    position: relative;

    .im {
      width: 160px;
      height: 160px;
      border-radius: 50%;
     border:2px solid rgb(162, 236, 196);
    }
    .profile {
      background-color: rgb(162, 236, 196);
      width: 160px;
      height: 160px;
      border-radius: 50%;
  
    }
    .editIcon {
        background-color: white;
        width: 30px;
        height: 30px;
        border-radius: 30px;
        cursor: pointer;
        position: absolute;
        left: 100px;
        top: 20px;
        .icon {
          margin-left: 5px;
        }
      }
  }

  .changeProfile {
    margin-top: 16px;
  }
  .UserDeatailBlock {
    .UserDeatailInnerBlock {
      .NameField {
        display: flex;
        justify-content: space-between;
      }
      .input {
        width: 461px;
        margin: 1em;
        border-radius: 4px;
      }
      .error {
        margin-left: 1em;
      }
      .btn {
        margin: 1em;
        width: 97%;
      }
    }
  }
}
