.BlogAdCard {
  .image_wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .center_text {
   position: absolute;
 
    .topMargin{
      margin-top:2vw;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    }
  }
}
