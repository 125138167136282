.chat_header {
  background-color: #f7f7f7;
  height: 130px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  position: sticky;
  top: 0;
  .img_text_group_card {
    // background-color: red;
    display: flex;
    .imageBox {
      //   background-color: green;
      background-color: yellow;
      height: 100px;
      border-radius: 50%;
      width: 100px;
      margin-left: 10px;
    }
    .group_left_text {
      //   background-color: blue;
      margin-top: 1rem;
      margin-left: 1.5rem;
    }
  }
}
