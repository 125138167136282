.pageNotFound {
  //   background-color: red;
  //   height: 100%;
  width: 100%;
  //   background-color: transparent;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  //   flex-wrap: wrap;
  //   row-gap: 2rem;

  .leftSideImg {
    width: 30%;
    // background-color: green;
  }
  .rightSideText {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: blue;
    .innerText {
      //   background-color: green;
      width: 60%;
    }
  }
}
@media only screen and (min-width: 300px) and (max-width: 991px) {
  .pageNotFound {
    width: 100%;
    //   background-color: transparent;
    margin-top: 2rem;
    display: flex;
    // justify-content: center;
    // align-items: center;
    flex-direction: column-reverse;
    .leftSideImg {
      width: 70%;
      //   background-color: blue;
    }
    .rightSideText {
      width: 100%;
      //   background-color: red;
    }
  }
}
