.Chat_info_model {
  //   padding: 10vw;
  .Info_img {
    padding: 10px;
    // margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    .imageBox {
      //   background-color: green;
      background-color: yellow;
      height: 100px;
      border-radius: 50%;
      width: 100px;
      margin-left: 10px;
    }
  }
  .desc_section {
    // background-color: red;
    // width: 100%;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-top: 2rem;
    .Add_grp_desc {
      display: flex;
      flex-direction: row;
    }
  }
  .addUser_section {
    // background-color: red;
    // width: 100%;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-top: 2rem;
    .icon_text {
      display: flex;
      gap: 10px;
    }
    .Add_members {
      display: flex;
      justify-content: space-between;
      padding: 5px;
      .members_list {
        display: flex;
        flex-direction: row;
        .img_card {
          .imageBox {
            background-color: yellow;
            height: 50px;
            border-radius: 50%;
            width: 50px;
            margin-left: 10px;
          }
          .group_left_text {
            margin-top: 1rem;
          }
        }
      }
    }
  }

  .destinationName {
    margin-top: 1.667vw;
  }
  .costBlock {
    display: flex;
    justify-content: baseline;
  }
  .lastRow {
    display: flex;
    justify-content: space-between;
    margin-top: 1.667vw;
    .exit_icon_txt {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
    .redText {
      color: #d13a3a;
      text-decoration: underline;
    }
    .buttonBlock {
      display: flex;
    }
  }
}
