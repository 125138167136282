.UserHeader {
  background-color: #0a9e88;
  height: 130px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;

  .user_header_img_txt {
    display: flex;
    .imageBox {
      background-color: yellow;
      height: 100px;
      border-radius: 50%;
      width: 100px;
      margin-left: 10px;
    }
    .group_left_text {
      //   background-color: blue;
      margin-top: 1rem;
    }
  }
}
