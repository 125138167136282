.helpBlock{
    position: fixed;
        bottom: 0;  
        right: 0;  
        z-index: 1;
    .innerHelp{
      display: flex;
      justify-content: center;
      align-items: center;
        width: 50px;
        height: 50px;
        background-color: #0A9E88;
        border-radius: 50%;
        cursor: pointer;
    }
    
}