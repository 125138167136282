.landingCreation {
  margin-top: 3vw;
  .viewAndManage {
    margin: 4px 0 32px 0;
  }
  .submit_reset {
    // background-color: red;
    display: flex;
    justify-content: end;
    gap: 20px;
  }
  .mainTitle {
    background-color: #f5fbfe;
    padding: 24px;
    margin-bottom: 48px;
    .InputFieldAndDropdown {
      .textField {
        width: 80%;
      }
      .dropdown {
        width: 20%;
        margin-left: 40px;
      }
      .vedioLink {
        width: 20%;
        margin-left: 40px;
        .textField {
          // background-color: red;
          width: 100%;
        }
      }
      display: flex;
      justify-content: baseline !important;
    }
    .inputFieldsSize {
      display: flex;
      gap: 30px;
      .textField {
        // background-color: red;
        width: 32%;
      }
      .autherDesc {
        // background-color: red;
        width: 70%;
      }
    }
    .inputFieldsViewLink {
      display: flex;
      gap: 30px;
      .dropdown {
        // background-color: red;
        width: 32%;
      }
      .textField {
        // background-color: red;
        width: 32%;
      }
    }
    .vedioDesc {
      // background-color: green;
      width: 100%;
      display: flex;
      gap: 40px;
      .descriptionField {
        // background-color: red;
        width: 77.5%;
      }
      .vedioSection {
        width: 20%;
        height: auto;
      }
    }
    .chooseImageUpload {
      width: 80%;
    }
  }
}
