.load_message {
  //   background-color: aquamarine;
  display: flex;
  flex-direction: column;
  align-items: center;
  .date_box {
    height: 40px;
    width: 190px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
