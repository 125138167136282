.group_card {
  height: 130px;
  width: 100%;
  border: 2px solid #d9dbe9;
  border-bottom: 1px solid #d9dbe9;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  .img_text_group_card {
    // background-color: red;
    display: flex;
    .imageBox {
      //   background-color: green;
      background-color: yellow;
      height: 100px;
      border-radius: 50%;
      width: 100px;
      margin-left: 10px;
    }
    .group_left_text {
      //   background-color: blue;
      margin-top: 1rem;
    }
  }
  .group_icons {
    // background-color: pink;
  }
}
