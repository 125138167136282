.signinSignup {
  background-color: black;
  background-image: url("../../assets/images/signsignup.svg");
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  // background-repeat: no-repeat;
  .textBlock {
    width: 80%;

    .subheadingClass {
      color: #fff;
      font-family: Lato;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 3.2px;
    }
  }
  .logo {
    // background-color: red;
    position: absolute;
    top: 10px;
    left: 10px;
  }
}
