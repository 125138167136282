.signInblock {
  margin: 0px;
  padding: 0px;
  display: flex;
  background-color: #161616;
  .leftPicBlock {
    width: 50%;
  }
  .signup {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // margin-left: 60px;
    .createText {
      text-align: left;
    }
    .letStarted {
      margin: 1.11vw 0vw 2.11vw 0vw;
    }
    .alreadyMember {
      margin-bottom: 1vw;
    }

    .forgotYourPass {
      text-align: right;
      margin: 1.111vw 0vw 1.111vw 0vw;
      color: #fff;
    }
    .createBtn {
      width: 33vw;
      height: 60px;

      margin-top: 1vw;
      margin-bottom: 1.667vw;
    }
  }
}
@media only screen and (min-width: 300px) and (max-width: 768px) {
  .signInblock {
    margin: 0px;
    padding: 0px;
    display: flex;
    background-color: #161616;
    .leftPicBlock {
      display: none;
    }
    .signup {
      width: 100%;
      height: 100vh;
      margin-left: 50px;
      .forgotYourPass {
        width: 80%;
      }
      .innerSignup {
        width: 90%;

        .createBtn {
          width: 80%;
          height: 60px;
          margin-top: 2vw;
          margin-bottom: 2.667vw;
        }
      }
      .inputWidth {
        width: 80%;
      }
    }
  }
}
// @media only screen and (min-width: 575px) and (max-width: 767px) {
// }
// @media only screen and (min-width: 300px) and (max-width: 574px) {
// }
