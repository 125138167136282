.blogThreeBlock {
  .contentBlock {
    margin: 6.944vw 20vw;
    text-align: justify;
    .headingBlock {
      margin-bottom: 4.167vw;
    }
    .paraOne {
      margin-bottom: 2.222vw;
    }
    .paraTwo {
      margin-top: 2.222vw;
    }
  }
  .bloglistMarginBottom {
    margin: 0vw 5.6vw 8.333vw 5.6vw;
  }
  .blogAd{
    margin: 0vw 5.6vw 8.333vw 5.6vw;
  }
}
