.firstCardBox {
  max-width: 400px;
  background-color: transparent;
  margin:1.1rem;
  cursor: pointer;
  .firstInnerBox {
    margin: 1rem;
    .imageBox {
      height: 30vh;
      border-radius: 10px;
    }
    .cardContent {
      margin-top: 21px;
      padding: 0px 0px 20px 0px;
      .headingSection {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.5rem;
        .iconNname {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0px;
          .rightPadding {
            margin-right: 2px;
          }
        }
      }
      .daysText {
        padding-bottom: 1rem;
      }
    }
    .lastRow {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .startPrice {
        display: flex;
        .startAt {
          padding-right: 0.6rem;
        }
      }
    }
  }
}
// @media only screen and (max-width: 1440px) {
//   .firstCardBox{
//     max-width: 340px;
//   }

// }