.UserChatModal {
  .back_close {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .userChat {
    .chat_field {
      //   background-color: red;
      display: flex;
      flex-direction: row-reverse;
    }
  }
}
