.chatpage {
  margin-top: 93px;
  //   margin-bottom: 6.944vw;
  //   background-color: red;
  .main_chatPage {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    .left_chat_content {
      border-right: 3px solid #d9dbe9;
      height: 99%;
      width: 34.72%;
    }
    .right_chat_content {
      height: 100vh;
      overflow: scroll;
      width: 65.28%;
      .chat_user_right {
        display: flex;
        align-items: end;
        justify-content: end;
        margin-right: 10px;
        .userChat {
          .chat_field {
            display: flex;
            flex-direction: row-reverse;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 300px) and (max-width: 991px) {
  .chatpage {
    margin-top: 57px;
    .main_chatPage {
      width: 100%;
    }
    .left_chat_content {
      width: 100% !important;
      // background-color: red;
    }
    .right_chat_content {
      display: none;
      width: 0;
    }
  }
}
// @media only screen and (min-width: 575px) and (max-width: 767px) {
//   .chatpage {
//     margin-top: 70px;
//     background-color: greenyellow;
//   }
// }
// @media only screen and (min-width: 300px) and (max-width: 574px) {
//   .chatpage {
//     margin-top: 60px;
//     background-color: blue;
//   }
// }
