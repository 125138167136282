.chat_field {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  .user_img {
    .imageBox {
      background-color: yellow;
      height: 74px;
      border-radius: 50%;
      width: 74px;
      margin-left: 10px;
    }
  }
  .chat_box {
    background-color: #e7e9ed;
    // width: 387px;
    height: 69px;
    border-radius: 0 10px 10px 10px;
    padding: 0 10px;
    .attach_chat {
      .imageBox {
        margin-top: 20px;
        max-width: 200px; // Set maximum width for the image
        max-height: 200px; // Set maximum height for the image
        border-radius: 8px; // Optional: Add border-radius for rounded corners
        object-fit: cover; // Ensure the image covers the box without stretching
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
          rgba(0, 0, 0, 0.23) 0px 3px 6px;
        background-color: #e7e9ed;
      }
    }
  }
}
