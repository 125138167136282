.blogListingPage {
  .imageBg {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .centerTextBox {
    position: absolute;
    text-align: center;
    width: 58vw;
  }
  .leftRightSpacing {
    margin: 4vw 5.5vw;
    .ourBlogsText {
      text-align: center;
      margin-bottom: 3vw;
      .bottomSpace {
        margin-bottom: 1.6vw;
      }
    }
    .cardCssOverRiding {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .firstCardBox {
        margin: 0px;
        margin-bottom: 3.8vw;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blogListingPage {
    .firstCardBox {
      max-width: max-content;
    }
  }
}
