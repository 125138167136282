.developerfirstCardBox {
  width: 100%;
  // max-width: 400px;
  background-color: transparent;
  // background-color: red;
  // margin: 1.1rem;
  margin-top: 2rem;
  display: flex;
  // align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 2rem;
  // background-color: red;
  cursor: pointer;
  .firstInnerBox {
    // background-color: green;
    width: 33%;
    display: flex;
    flex-direction: column;
    // margin: 1rem 2rem 0 1rem;
    align-items: center;
    .imageBox {
      background-color: green;
      height: 300px;
      border-radius: 20%;
      width: 300px;
    }
    .cardContent {
      margin-top: 30px;
      padding: 0px 0px 20px 0px;
      .headingSection {
        // display: flex;
        // justify-content: space-between;
        // margin-bottom: 1.5rem;
        .iconNname {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px;
        }
      }
      .daysText {
        padding-bottom: 1rem;
      }
      .sub-name {
        // background-color: red;
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .lastRow {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .startPrice {
        display: flex;
        .startAt {
          padding-right: 0.6rem;
        }
      }
    }
  }
}
// .css-bhp9pd-MuiPaper-root-MuiCard-root {
//   box-shadow: none;
// }
// @media only screen and (max-width: 1440px) {
//   .firstCardBox{
//     max-width: 340px;
//   }

// }
@media only screen and (min-width: 300px) and (max-width: 991px) {
  .developerfirstCardBox {
    row-gap: 3rem;
    .firstInnerBox {
      width: 100%;
    }
  }
}
