.ChangePasswordTab {
  margin-top: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  .ChangePassword {
    .input {
        width: 461px;
        margin:1em 0em ;
        border-radius: 4px;
      }
      .btn {
        margin:1em 0em ;
        width: 100%;
      }
  }
}
