.titleBox{
    display:flex;
    margin: 12px 0px !important;
    .title{
        background-color:#D5FBFB ;
        padding:8px 24px;
        border-radius: 4px;
    }
    .items{
        background-color:#D5FBFB ;
        padding:8px 24px;
        border-radius: 4px;

    }
}