
.parent_footer_chat{
  bottom: 0;
  position:sticky;
.footer_chat {
  width: 100%;
  height: 100px;
  background-color: #d9dbe9;
  //   position: fixed;
  //   height: 100vh;
  //   bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 20px;
  justify-content: space-between;
  .input_box {
    width: 80%;
    // padding: 20px;
    // background-color: white;
    margin-left: 1rem;
  }
  .send_icons {
    // background-color: red;
    display: flex;
    padding-right: 20px;
    gap: 10px;

    .attachemet_icon {
      height: 60px;
      width: 60px;
      //   background-color: black;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .imageBox {
      //   background-color: green;
      background-color: #0a9e88;
      height: 60px;
      border-radius: 50%;
      width: 60px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
}