.bookingDetailsPageBlock {
    .leftRightMargin{
        margin-left: 6vw;
        margin-right: 6vw;
        .tourText {
            margin-top: 5.903vw;
            margin-bottom: 3.333vw;
          }
    }
 
}
