.helpForm {
  position: fixed;
  top: 100px;
  right: 50px;
  z-index: 1000;
  max-width: 300px;
  height: 80vh;

  overflow-y: auto;
  .headingSection {
    background-color: #0a9e88;
    padding: 24px 32px;
    .heading_help {
      display: flex;
      justify-content: space-between;
      .personal_chat_btn {
        margin-left: 5px;
        .btn_chat {
          background-color: yellow;
          font-size: 8px;
          font-weight: bold;
          color: black;
        }
      }
    }
  }
  .textFieldSection {
    background-color: #f3f3f3;
    display: flex;
    justify-content: center;
    padding: 24px 24px;
    flex-direction: column;
    .textFieldInnerBox {
      width: 100%;
      .supportTextFiled {
        width: 100%;
      }
    }
  }
}
